import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import "../../assets/css/Slider.scss"

type ArticleType = {
    id: number,
    slug: string,
    title: string,
    imgId: string
}
 const Article:React.FC<ArticleType> = ({id, title, imgId}) => {
    const [src, setSrc] = useState<string>('')

    const findThumb = async(imgId: string) => {
        console.log(imgId);
        await axios(`https://api.noguchi.com.ua/wp-json/wp/v2/media/${imgId}` )
        .then((res: any) => setSrc(res.data.source_url)) 
    }
    useEffect(()=>{
        findThumb(imgId);
    }, [imgId])
    
  return (
    <div className="vlog__Item">
        <img src={src} alt="" />
        <p>{title}</p>
        
        <Link to={`/vlog/${id}`} className="moreBtn">Детальніше</Link>
    </div>
    
  )
}

export default Article
