import React,{ useEffect, useState} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/Slider.scss"
import {Article} from "../"

import { Link } from 'react-router-dom'
import axios from 'axios'
import { CustomArrowProps, default as Slider } from "react-slick";

import prev from "../../assets/img/prev.svg"
import next from "../../assets/img/next.svg"


const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <img src={next} alt="" />
    </div>
  );
};
const PrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <img src={prev} alt="" />
    </div>
  );
};

type TSliderProps = {
    adaptiveHeight?: false,
    className?: string,
    infinite?: boolean,
    dots?: boolean,
    autoplay?: boolean,
    centerMode?: boolean,
    centerPadding?: string,
    speed?: number,
    autoplaySpeed?: number,
    slidesToScroll?: number,
    slidesToShow?: number,
    nextArrow?: JSX.Element,
    prevArrow?: JSX.Element,
    beforeChange?: (currentSlide: number, nextSlide: number) => void,
    responsive?: { breakpoint: number; settings: any }[]
  
  }

  type ArticleType = {
    id: string,
    title: string,
    imgId?: number
}
const VlogSlider: React.FC = () => {

    const [items, setItems] = useState<ArticleType[]>([])

    const getArticles = async() => {
        try{
            await axios('https://api.noguchi.com.ua/wp-json/wp/v2/vlog')
            .then((res) => setItems(res.data))
        }catch(e){

        }
    }
    useEffect(()=>{
        getArticles()
    }, [])

    console.log(items);
    const settings: TSliderProps = {
        className: 'vlog_carousel',
        infinite: true,
        dots: true,
        speed: 1000,
        autoplay: false,
        autoplaySpeed: 2500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        //beforeChange: (current, next) => setImageIndex(next),
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 1,
              adaptiveHeight: true,
              // className: "center",
              // slidesToShow: 1,
              centerPadding: "0",
              centerMode: true,
            },
          },
        ],
      };


  return (
    
      <div className="vlog_box">
        <h2>Noguchi Vlog</h2>
        <Slider {...settings} >
            {items && items?.map((el: any, i: number) => <Article key={i} id={el.id} slug={el.slug} title={el.title.rendered}  imgId={el.featured_media} />)}
        </Slider>
      </div>
   
  )
}
export default VlogSlider