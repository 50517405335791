
export const categories = [
    // { title: 'Десерти', link: 'deserts', img: 'img/moty.png' },
    // { title: 'Закуски', link: 'zakusky', img: 'img/nori.png' },
    // { title: 'Локшина звичайна', link: 'lokshyna', img: 'img/lapsha.png' },
    // { title: 'Локшина швидкого приготування', link: 'lokshyna-shvydka', img: 'img/shape.png' },
    // { title: 'Напої', link: 'napoi', img: 'img/tea.png' },
    // { title: 'Побутові прилади', link: 'pobutovi-prylady', img: 'img/palochki.png' },
    // { title: 'Приправи', link: 'prypravy', img: 'img/pripravy.png' },
    // { title: 'Соуси', link: 'sousy', img: 'img/sous.png' },

    { title: 'Сніданки', link: 'snidanky', img: 'img/shape.png' },
    { title: 'Обіди', link: 'obidy', img: 'img/lapsha.png' },
    { title: 'Вечері', link: 'vecheri', img: 'img/sous.png' },
    { title: 'Кольорові сети', link: 'color-sets', img: 'img/pripravy.png' },
    { title: 'Десертні набори', link: 'desert-sets', img: 'img/moty.png' },
    { title: 'Рамени', link: 'rameny', img: 'img/nori.png' },
  ]